<template>
    <div>
        <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
            <b-card>
                <b-row class="text-center">
                    <b-card-title class="mx-auto">
                        Overall wellbeing index
                    </b-card-title>
                </b-row>

                <b-row>
                    <b-card-sub-title class="mx-auto mb-2">
                        <what-does-this-mean type="wellbeing-score-explanation" />
                    </b-card-sub-title>
                </b-row>

                <b-row class="no-gutters">
                    <div class="d-flex justify-content-end w-100">
                        <div v-if="wellbeingIndexData.length > 0">
                            {{wellbeingIndexData[0].average_score_for_feeling}}/10
                        </div>
                    </div>
                    <div class="w-100" v-if="wellbeingIndexData.length > 0">
                        <b-progress
                            :value="wellbeingIndexData[0].average_score_for_feeling"
                            max="10"
                            variant="mhc-dark"
                            height="3rem"
                            width="100%"
                            class="mt-05 mb-05 border-radius-0"
                        />
                    </div>

                    <div class="d-flex justify-content-between w-100" v-if="wellbeingIndexData.length > 0">
                        <div>
                            <div>
                                1 =
                            </div>
                            <div>
                                Fixed
                            </div>
                            <div>
                                Wellbeing
                            </div>
                        </div>
                        <div>
                            <div>
                                10 =
                            </div>
                            <div>
                                Growth
                            </div>
                            <div>
                                Wellbeing
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p class="mb-0 py-3 text-center">No mental health check results data was found for the selected period</p>
                    </div>

                </b-row>

                <b-row>
                    <b-button
                        @click="openForm"
                        variant="primary"
                        block
                        class="mt-3 mx-md-2 mx-0"
                        >Start another mental health check
                    </b-button>
                </b-row>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import { BRow, BCol, BOverlay, BButton, BAlert, BCard, BCardTitle, BCardSubTitle, BCardHeader, BCardBody, BProgress } from "bootstrap-vue";
import WhatDoesThisMean from "../../modals/WhatDoesThisMean.vue";

export default {
    components: {
        BRow, BCol, BOverlay, BButton, BAlert, BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BProgress,
        WhatDoesThisMean
    },
    computed: {
        show() {
           return this.$store.state.formStoreModule.show;
        },
    },
    props: ["wellbeingIndexData"],
    data() {
        return {
            value: 40
        }
    },
    methods: {
        openForm() {
            this.$router.push({ name: 'form' });
        },
    },

}
</script>

<style>
    .border-radius-0 {
        border-radius: 0;
    }

    .border-radius-0 .progress-bar {
        border-top-right-radius: 0rem!important;
        border-bottom-right-radius: 0rem!important;
    }

    .mhc-link-blue-color {
        color: #245bf0;
    }
</style>