<template>
    <div class="p-0">
        <b-modal
            id="feelings-index-details-modal"
            ref="feelings-index-details-modal"
            hide-footer
            centered
            size="lg"
        >
            <template #modal-title>Latest {{ data.type }} feelings index details</template>
            <div class="d-block py-2 px-1">
                <h3 class="font-weight-normal mt-2 text-center">
                    {{ data.type }}
                </h3>
            </div>

            <b-row class="no-gutters mb-3 px-md-5" v-for="(result, index) in data.results" :key="index">
                <div class="d-flex justify-content-between w-100">
                    <div>
                        {{ result.questionnaire.title }}
                    </div>
                    <div>
                        {{ result.average_score_for_questionnaire }}/10
                    </div>
                </div>

                <div class="w-100">
                    <b-progress
                        :value="result.average_score_for_questionnaire * 10"
                        max="100"
                        variant="mhc-dark"
                        height="3rem"
                        width="100%"
                        class="mt-05 mb-05 border-radius-0"
                    />

                    <div class="d-flex" style="justify-content:space-between;">
                        
                        <what-does-this-mean type="details-emotions-my-hub" :message="result.message" :professional="result.professional" :subcategory="result.questionnaire.title" :score="result.average_score_for_questionnaire" />

                        <router-link :to="{name: 'learn-and-develop-grid', params: { 
                            questionnaire: result.questionnaire_id
                            } }">
                            <span class="cursor-pointer mhc-link-blue-color font-weight-bold">
                                View recommended toolkits
                            </span>
                        </router-link>
                    </div>
                </div>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { BLink, BModal, BButton, BRow, BProgress } from "bootstrap-vue";
import EventBus from "@/main.js"
import WhatDoesThisMean from "../../modals/WhatDoesThisMean.vue";

export default {
    components: {
        BLink,
        BModal,
        BButton,
        BRow,
        BProgress,
        WhatDoesThisMean
    },
    data() {
        return {
            data: {}
        }
    },
    methods: {
        openModal() {
            if (this.$refs["feelings-index-details-modal"] != undefined) {
                // Show modal
                this.$refs["feelings-index-details-modal"].show();
            }
        },
        openQuestionnaireDetailsModal(result) {
            
        }
    },
    mounted() {
        EventBus.$on('openFeelingsIndexDetailsModal', (feelings) => {
            this.data = feelings
            
            this.openModal()
        });
    }
};
</script>

