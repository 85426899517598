<template>
  <b-tabs content-class="pb-4" v-model="tabIndex">
    <b-tab title="Trait Score" lazy>
      <trait-score />
    </b-tab>
     
    <b-tab title="Feelings index" lazy>
      <feelings-index />
    </b-tab>
     
    <b-tab title="Wellbeing index" lazy>
      <wellbeing-index />
    </b-tab>

    <b-tab title="Daily Check-in" lazy>
      <daily-check-ins />
    </b-tab>
  </b-tabs> 
</template>

<script>
import { BTabs, BTab} from "bootstrap-vue";
import TraitScore from "../components/my-hub/main/TraitScore.vue";
import FeelingsIndex from "../components/my-hub/main/FeelingsIndex.vue";
import WellbeingIndex from "../components/my-hub/main/WellbeingIndex.vue";
import DailyCheckIns from "../components/my-hub/main/DailyCheckIns.vue";
import EventBus from '@/main';

export default {
  components: {
    BTabs,
    BTab,
    TraitScore,
    FeelingsIndex,
    WellbeingIndex,
    DailyCheckIns
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  mounted() {
    if(this.tabIndex != 3) {
      if(sessionStorage.getItem("goToDailyCheckInTab") == "true") {      
        sessionStorage.setItem("goToDailyCheckInTab", false);
        setTimeout(() => this.tabIndex = 3, 2000);
      }
    } else {
      EventBus.$emit("getNewCheckInsData")
    }
    
    EventBus.$on("goToDailyCheckInTab", () => {
      if(this.tabIndex != 3) {
        this.tabIndex = 3
      } else {
        EventBus.$emit("getNewCheckInsData")
      }
    });
  },
}
</script>