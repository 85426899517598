<template>
    <div>
        <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
            <b-row class="match-height">
                <b-col lg="5">
                    <todays-check-in :key="key" />
                </b-col>
                
                <b-col lg="7">
                    <b-card no-body>
                        <b-card-header class="pb-1">
                            Select period
                        <b-card-title class="mb-0 w-100 line-chart-title-date">

                        </b-card-title>
                        </b-card-header>

                        <b-card-body>
                            <div class="w-25">
                                <flat-pickr
                                    v-model="rangeDate"
                                    class="form-control"
                                    :config="options"
                                    :placeholder="from + ' to ' + to"
                                />
                            </div>
                        </b-card-body>
                    </b-card>
                    
                    <todays-check-in-line-chart :lineChart="lineChart" :key="key" />
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>
import { BRow, BCol, BOverlay, BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TodaysCheckIn from '../dailyCheckIns/TodaysCheckIn.vue';
import TodaysCheckInLineChart from '../dailyCheckIns/TodaysCheckInLineChart.vue'
import flatPickr from 'vue-flatpickr-component'
import EventBus from '@/main.js';

export default {
    components: {
        BRow, BCol, BOverlay,
        TodaysCheckIn,
        TodaysCheckInLineChart,
        BCard, BCardBody, BCardHeader, BCardTitle,
        flatPickr
    },
    data() {
        return {
            feelingsIndexData: [],
            feelingsIndexLineChartData: [],
            show: false,
            rangeDate: null,
            key: 1,
            options: {
                mode: 'range',
                onChange: function(rawdate, altdate, FPOBJ) {
                    if(altdate.includes(" to ")) {
                        let range = altdate.split(' to ')
                        let from = range[0]
                        let to = range[1]

                        // Emit event to get fresh analytics data from Home.vue
                        EventBus.$emit('getNewCheckInsData', { from: from, to: to })

                        FPOBJ.close(); // Close datepicker on date select
                        FPOBJ._input.blur(); // Blur input field on date select
                    }
                }
            },
            from_date: "",
            to_date: "",
            from: "",
            to: "",

            lineChart: {
                series: [
                {
                    name: "Feelings index",
                    data: [],
                },
                ],
                chartOptions: {
                chart: {
                    zoom: {
                    enabled: false,
                    },
                    toolbar: {
                    show: false,
                    },
                },
                markers: {
                    strokeWidth: 7,
                    strokeOpacity: 1,
                    strokeColors: ["#59949c"],
                    colors: ["#59949c"],
                },
                colors: ["#59949c"],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },
                grid: {
                    xaxis: {
                    lines: {
                        show: true,
                    },
                    },
                },
                tooltip: {
                    custom(data) {
                    return `${'<div class="px-1 py-50"><span>'}${
                        data.series[data.seriesIndex][data.dataPointIndex]
                    }</span></div>`;
                    },
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show:true,
                        style: {
                            colors: ['#FF0000','#FF0000','#FF0000','#FF0000','#FF0000','#FF0000','#FF0000'],
                            fontSize: '13px'
                        },
                        rotate: -35,
                        rotateAlways: true,    
                        offsetY: 15                    
                    }
                },
                yaxis: {
                    min: 1,
                    max: 5,
                    tickAmount: 4,
                    labels: {
                        formatter: (value) => {
                            value = (value + "");
                            if(value.includes(5)) {
                                return "Great";
                            } else if(value.includes(4)) {
                                return "Good";
                            } else if(value.includes(3)) {
                                return "Ok";
                            } else if(value.includes(2)) {
                                return "Bad";
                            } else if(value.includes(1)) {
                                return "Terrible";
                            }
                            return parseInt(value) 
                        },
                        style: {
                            color: ['#FF0000','#FF0000','#FF0000','#FF0000','#FF0000'],
                            fontSize: '13px'
                        },
                    },
                    title: {
                        text: ""
                    }
                },
                },
            },
        }
    },
    methods: {
        getCheckInsData(from = "", to = "") {
            this.show = true;
            this.$http.get("/api/auth/last-7-check-ins?from=" + from + "&to=" + to)
                .then((res) => {

                    this.from = res.data[0].full_date.split("T")[0]
                    this.to = res.data[6].full_date.split("T")[0]

                    let lineChartValues = [];
                    let moodsResults = [];

                    res.data.forEach(element => {
                        let currentArray = [element.week_day];

                        if(element.today == true) {
                            currentArray.push("TODAY");
                        } else {
                            currentArray.push(element.month + " " + element.day);
                        }
                        lineChartValues.push(currentArray)

                        moodsResults.push(element.mood_result)

                    });

                    this.lineChart.chartOptions.xaxis.categories = lineChartValues;
                    this.lineChart.chartOptions.xaxis.labels.style.colors = ['#FF0000','#FF0000','#FF0000','#FF0000','#FF0000','#FF0000','#FF0000'];

                    this.lineChart.series[0].data = moodsResults;
                    this.show = false;
                    this.key++;
                })
                .catch((error) => {
                    console.log(error)
                    this.show = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again login",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                });
        }
    },
    mounted() {
        this.getCheckInsData();
        EventBus.$on('getNewCheckInsData', (data = null) => {
            if(data == null) {
                this.getCheckInsData();
            } else {
                this.getCheckInsData(data.from, data.to);
            }
        });
    }
}
</script>

<style>
    .border-radius-0 {
        border-radius: 0;
    }

    .border-radius-0 .progress-bar {
        border-top-right-radius: 0rem!important;
        border-bottom-right-radius: 0rem!important;
    }
</style>