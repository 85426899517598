<template>
    <div>
        <b-row class="match-height">
            <b-col lg="4">
                <overall-feelings-index :feelingsIndexData="feelingsIndexData" />
            </b-col>
            
            <b-col lg="8">
                <feelings-index-line-chart :lineChart="lineChart" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BRow, BCol, BOverlay } from "bootstrap-vue";
import OverallFeelingsIndex from "../feelings-index/OverallFeelingsIndex.vue";
import FeelingsIndexLineChart from "../feelings-index/FeelingsIndexLineChart.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BRow, BCol, BOverlay,
        OverallFeelingsIndex,
        FeelingsIndexLineChart
    },
    data() {
        return {
            feelingsIndexData: [],
            feelingsIndexLineChartData: [],

            lineChart: {
                series: [
                {
                    name: "Feelings index",
                    data: [],
                },
                ],
                chartOptions: {
                chart: {
                    zoom: {
                    enabled: false,
                    },
                    toolbar: {
                    show: false,
                    },
                },
                markers: {
                    strokeWidth: 7,
                    strokeOpacity: 1,
                    strokeColors: ["#59949c"],
                    colors: ["#59949c"],
                },
                colors: ["#59949c"],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },
                grid: {
                    xaxis: {
                    lines: {
                        show: true,
                    },
                    },
                },
                tooltip: {
                    custom(data) {
                    return `${'<div class="px-1 py-50"><span>'}${
                        data.series[data.seriesIndex][data.dataPointIndex]
                    }</span></div>`;
                    },
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    min: 0,
                    max: 10,
                    tickAmount: 10,
                    labels: {
                    formatter: (value) => { return parseInt(value) },
                    },
                    title: {
                    text: "Score out of 10"
                    }
                },
                },
            },
        }
    },
    methods: {
        getFeelingsIndex() {
            this.$http.get("/api/auth/feelings/analytics")
                .then((res) => {
                    if(res != undefined) {
                        this.feelingsIndexData = res.data.feelings_index_data
                        this.feelingsIndexLineChartData = res.data.feelings_index_line_chart_data
                    }
                })
                .then(() => {
                    // Fill LineChart values and options
                    let lineChartValues = [];
                    let lineChartOptions = [];

                    for(let y = 0; y < Object.keys(this.feelingsIndexLineChartData).length; y++) {
                        let year = Object.keys(this.feelingsIndexLineChartData)[y]

                        for(let m=0; m<Object.keys(this.feelingsIndexLineChartData[year]).length; m++) {
                            let month = Object.keys(this.feelingsIndexLineChartData[year])[m]

                            let axisName = year +'/'+ month

                            lineChartOptions.push(axisName)

                            lineChartValues.push(this.feelingsIndexLineChartData[year][month].avg_score);
                        } 
                    }

                    this.lineChart.chartOptions.xaxis.categories = lineChartOptions;
                    this.lineChart.series[0].data = lineChartValues;
                })
                .catch((error) => {
                    console.log(error)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again login",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                });
        }
    },
    mounted() {
        this.getFeelingsIndex()
    }
}
</script>

<style>
    .border-radius-0 {
        border-radius: 0;
    }

    .border-radius-0 .progress-bar {
        border-top-right-radius: 0rem!important;
        border-bottom-right-radius: 0rem!important;
    }
</style>