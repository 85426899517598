<template>
  <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
    <b-card no-body>
      <b-card-header>
        <div class="w-100">
          <b-card-title class="mb-1 w-100 line-chart-title-date">
            <div>
              Daily Check-in progress chart
            </div>
          </b-card-title>
        </div>

      </b-card-header>
      
      <b-card-body>

        <div v-if="lineChart.series[0].data.length > 0">
          <vue-apex-charts
            v-if="lineChart.chartOptions.xaxis.categories.length > 0"
            ref="lineChart"
            type="line"
            height="400"
            class="m-auto w-100"
            :options="lineChart.chartOptions"
            :series="lineChart.series"
          />
        </div>
        <div v-else class="d-flex justify-content-center align-items-center">
          <p class="mb-0 py-3 text-center">No mental health check results data was found for the selected period</p>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge, BFormSelect
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import WhatDoesThisMean from '../../../components/modals/WhatDoesThisMean.vue'

export default {
  components: {
    VueApexCharts,
    flatPickr,
    WhatDoesThisMean,
    BOverlay,
    BCardHeader,
    BCard,
    BBadge,
    BFormSelect,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: ["lineChart"],
  data() {
    return {
      selectedFeeling: "",
      optionsFeeling: [
        {value: '', text: 'All'}
      ],
    }
  },
  computed: {
    show() {
      return this.$store.state.formStoreModule.show;
    },
  },
  methods: {
  },
  mounted() {
    
  },
  watch: { 
   	$props: {
      handler(value) {
        
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
