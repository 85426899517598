<template>
  <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
    <b-card no-body>
      <b-card-header>
        <!-- title, date and subtitle -->
        <div class="w-100">
          <b-card-title class="mb-1 w-100 line-chart-title-date">
            <div>
              Wellbeing index chart
            </div>
          </b-card-title>
          <!--
            <b-card-sub-title><what-does-this-mean type="home-circle-chart-bottom" /></b-card-sub-title>
          -->
        </div>
        <!--/ title and subtitle -->
      
      </b-card-header>
      
      <b-card-body>

        <div v-if="lineChartWellbeing.series[0].data.length > 0">
          <vue-apex-charts
            v-if="lineChartWellbeing.chartOptions.xaxis.categories.length > 0"
            ref="lineChartWellbeing"
            type="line"
            height="400"
            class="m-auto w-100"
            :options="lineChartWellbeing.chartOptions"
            :series="lineChartWellbeing.series"
          />
        </div>
        <div v-else class="d-flex justify-content-center align-items-center">
          <p class="mb-0 py-3 text-center">No mental health check results data was found for the selected period</p>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge, BFormSelect
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import WhatDoesThisMean from '../../../components/modals/WhatDoesThisMean.vue'

export default {
  components: {
    VueApexCharts,
    flatPickr,
    WhatDoesThisMean,
    BOverlay,
    BCardHeader,
    BCard,
    BBadge,
    BFormSelect,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: ["lineChartWellbeing"],
  data() {
    return {
      selectedFeeling: "",
      optionsFeeling: [
        {value: '', text: 'All'}
      ],
    }
  },
  computed: {
    show() {
      return this.$store.state.formStoreModule.show;
    },
  },
  methods: {

    getWellbeingQuestionnairesData() {
      this.$store.commit('formStoreModule/setShow', true)

      this.$http.get("/api/auth/wellbeing/analytics")
        .then((res) => {
          console.log(res)
          if(res != undefined) {
            let chartData = res.data.wellbeing_index_line_chart_data
            
            // Fill LineChart values and options
            let lineChartValues = [];
            let lineChartOptions = [];

            for(let y = 0; y < Object.keys(chartData).length; y++) {
                let year = Object.keys(chartData)[y]

                for(let m=0; m<Object.keys(chartData[year]).length; m++) {
                    let month = Object.keys(chartData[year])[m]

                    let axisName = year +'/'+ month

                    lineChartOptions.push(axisName)

                    lineChartValues.push(chartData[year][month].avg_score);
                } 
            }

            this.$refs.lineChartWellbeing.updateSeries([{
              data: lineChartValues
            }])

            this.$refs.lineChartWellbeing.updateOptions([{
              xaxis: {
                categories: lineChartOptions
              }
            }])

            this.$store.commit('formStoreModule/setShow', false);
          }
        })
        .catch((err) => {
          console.log(err);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    }
  },
  mounted() {
    
  },
  watch: { 
   	$props: {
      handler(value) {
        if(this.$refs.lineChartWellbeing != undefined) {
          this.$refs.lineChartWellbeing.updateSeries([{
            data: value.data.series[0].data
          }])

          this.$refs.lineChartWellbeing.updateOptions([{
            xaxis: {
              categories: value.data.chartOptions.xaxis.categories
            }
          }])
        }
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
