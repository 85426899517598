<template>
    <section class="pb-4">
        <v-tour name="myTour" :steps="steps"></v-tour>

        <b-row v-if="showVerificationMessage">
            <b-col lg="12">
                <b-alert
                variant="primary"
                show
                >
                <div class="alert-body">
                    <span>For full access, please verify your email. <span class="cursor-pointer" @click="resendVerificationEmail"><u>Resend verification email</u></span> if you haven’t received one.</span>
                </div>
                </b-alert>
            </b-col>
        </b-row>

        <b-row class="match-height">
            <b-col lg="4">
              <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
                <average-score-circle-chart :data="data.circleChart" v-bind:chartColor="cColor" :barCategories="barCategories" :bar="data.barChart" :barColors="bColors" title="Your overall mental health trait score" type="home-circle-chart" questionnaireCategory="My mental health and my life situation(s)" :message="data.circleChart.series[1]"/>
              </b-overlay>
            </b-col>
            
            <b-col lg="8">
              <b-overlay :show="show" rounded="sm">
                <range-datepicker :from="from_period" :to="to_period" />
              </b-overlay>
              
              <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
                <average-score-line-chart
                  :data="data.lineChartSimple"
                  title="Mental health check progress chart"
                  type="home-line-chart"
                />
              </b-overlay>
            </b-col>
        </b-row>

        <b-row class="match-height">
            <b-col lg="12">
                <recommended-toolkits showButton />
            </b-col>
        </b-row>

        <b-modal
            id="onboard-modal-1"
            centered
            ok-only
            hide-footer
            hide-header
            ref="onboard-modal-1"
        >

            <h2>Hi {{ fullname }} </h2>
            <p>Welcome to Mental Health Check.</p>
            <p>We are on a mission to ensure you feel confident and proactive at ...</p>
            <ol style="padding-left: 20px; font-weight: bold;">
                <li>Understanding your mental health</li>
                <li>Managing your mental health</li>
                <li>Maintaining good mental health</li>
                <li>Talking about your mental health</li>
                <li>Reaching out for support</li>
            </ol>
            <b-button
                block
                variant="primary"
                size="md"
                @click="nextScreen()">
                Next
            </b-button>
        </b-modal>

        <b-modal
            id="onboard-modal-2"
            centered
            ok-only
            hide-footer
            ref="onboard-modal-2"
        >
            <p>We are in Beta so bear with us.</p>
            <p>
                This means that not everything will be perfect as we are making some final tweaks but with your help using the 
                feedback link on the right of you we can make mental health check as effective and easy to use as possible
            </p>
            <p style="font-weight: bold">
                While we're in beta we are working at full speed to roll out site improvements and new features
            </p>
            <b-button
                block
                variant="primary"
                size="md"
                @click="gotIt()">
                Got it
            </b-button>
        </b-modal>

        <b-modal
            id="onboard-modal-3"
            centered
            ok-only
            hide-footer      
            no-close-on-backdrop
            hide-header
            ref="onboard-modal-3"
        >
            <div class="p-1">
                <p class="font-weight-normal">Before continuing to your dashboard, please complete your first mental health check.</p>
                <b-button variant="primary" size="md" @click="goToTheQuestionnairesPage">
                    Start Mental Health Check
                </b-button>
            </div>
        </b-modal>

    </section>
</template>

<script>
import store from "@/store/index";
import { BTabs, BTab, BRow, BCol, BOverlay, BButton, BAlert, BCard, BCardHeader, BCardBody } from "bootstrap-vue";
import AverageScoreCircleChart from "../AverageScoreCircleChart.vue";
import AverageScoreLineChart from "../AverageScoreLineChart.vue";
import RecommendedToolkits from "../../toolkit/RecommendedToolkits.vue";
import RangeDatepicker from "../RangeDatepicker.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from '@/main.js';

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BTabs,
    BTab,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BAlert,
    AverageScoreCircleChart,
    AverageScoreLineChart,
    RecommendedToolkits,
    RangeDatepicker,
  },
  computed: {
    show() {
      return store.state.formStoreModule.show;
    },
    showVerificationMessage() {
      return store.getters["app/showVerificationMessage"]
    },
  },
  data() {
    return {
      fullname: "",
      bColors: [],
      cColor: "",
      barCategories: ["Biological", "Psychological", "Social"],
      data: {
        circleChart: {
          series: [20, 80],
        },
        lineChartSimple: {
          series: [
            {
              name: "Series A",
              data: [],
            },
          ],
          chartOptions: {
            chart: {
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            markers: {
              strokeWidth: 7,
              strokeOpacity: 1,
              strokeColors: ["#59949c"],
              colors: ["#59949c"],
            },
            colors: ["#59949c"],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            tooltip: {
              custom(data) {
                return `${'<div class="px-1 py-50"><span>'}${
                  data.series[data.seriesIndex][data.dataPointIndex]
                }</span></div>`;
              },
            },
            xaxis: {
              categories: []
            },
            yaxis: {
              min: 0,
              max: 10,
              tickAmount: 10,
              labels: {
                formatter: (value) => { return parseInt(value) },
              },
              title: {
                text: "Score out of 10"
              }
            },
          },
        },
        barChart: [],
      },
      from_period: "",
      to_period: "",

      // Tour steps
      steps: [
        {
          target: '.navbar-container',
          header: {
            title: 'Navbar',
          },
          content: 'You can use it for navigation',
        },
        {
          target: '#cir-ch-prev',
          header: {
            title: 'Your overall mental health trait score',
          },
          content: 'You can see your overall mental health trait score',
          params: {
            placement: 'bottom'
          }
        },
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    handleUserNotVerified() {

      this.$http.get("/api/auth/user/verified")
      .then((res) => {
        if(res.data.verified == true) {
          // if the user is actually verified, then we just reset the user data
          // Set user data in localstorage
          let currentUserData = JSON.parse(localStorage.getItem('userData'))
          currentUserData.email_verified_at = "it is verified";
          store.commit("app/setUserData", currentUserData);

          //this.showVerificationMessage = false
          store.dispatch('app/showVerificationMessage', false)
        } else {
          //this.showVerificationMessage = true
          store.dispatch('app/showVerificationMessage', true)

          this.scrollToTop()

          // Show toast for verification
          this.$toast({
            component: ToastificationContent,
            props: {
                title: "For full access, please verify your email",
                icon: "AlertCircleIcon",
                variant: "info",
                html: "<div><span class='cursor-pointer'><u>Resend verification email</u></span> if you haven’t received one.</div>"
            },
          });
        }
      })
      .catch((error) => {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again login",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });

        //this.showVerificationMessage = true
        store.dispatch('app/showVerificationMessage', true)

        this.scrollToTop()
      });
    },
    // Resend verification email event
    resendVerificationEmail() {
      //this.showVerificationMessage = false
      store.dispatch('app/showVerificationMessage', false)
      
      this.$http.get("/api/auth/user/verification-link")
      .then((res) => {
        this.$toast({
            component: ToastificationContent,
            props: {
            title: "Verification email sent successfully",
            icon: "EditIcon",
            variant: "success",
            },
        });
      })
      .catch((error) => {
        console.log(error);

        //this.showVerificationMessage = true
        store.dispatch('app/showVerificationMessage', true)

        this.scrollToTop()
        
        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong with verification email. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      });
    },
    nextScreen() {
      this.$refs['onboard-modal-1'].hide();
      this.$refs['onboard-modal-2'].show();
    },
    gotIt() {
      this.$refs['onboard-modal-2'].hide();     
    },
    goToTheQuestionnairesPage() {
      this.$router.push({ path: 'form' })
    },
    getAnalytics(from = "", to = "") {
      store.commit("formStoreModule/setShow", true);
      
      this.$http.get(`/api/auth/trait/analytics?from=${from}&to=${to}`)
      .then((res) => {
        if(res != undefined) {
          // Fill Average score
          /*if (res.data.average_score.score == 0) {
            res.data.average_score.score = 10;
          }*/

          this.data.circleChart.series = [
            res.data.average_score.score,
            10 - res.data.average_score.score,
          ].reverse();          
          
          if(res.data.average_score.score > 9.7) {
            this.data.circleChart.series = [10, 0.3].reverse();
          }

          if(res.data.average_score.score == 0) {
            //this.data.circleChart.series = [10, 0.3];
            this.data.circleChart.series = []
          }
                    
          // Fill LineChart values and options
          let lineChartValues = [];
          let lineChartOptions = [];
         
          for(let y = 0; y < Object.keys(res.data.scores_by_year).length; y++) {
            let year = Object.keys(res.data.scores_by_year)[y]

            for(let m=0; m<Object.keys(res.data.scores_by_year[year]).length; m++) {
              let month = Object.keys(res.data.scores_by_year[year])[m]

              let axisName = year +'/'+ month

              lineChartOptions.push(axisName)

              lineChartValues.push(res.data.scores_by_year[year][month].avg_score);
            } 
          }

          this.data.lineChartSimple.chartOptions.xaxis.categories = lineChartOptions;
          this.data.lineChartSimple.series[0].data = lineChartValues;
          this.from_period = res.data.from_date
          this.to_period = res.data.to_date

          // Fill BarChart values.
          let biological = res.data.average_score_bio
          let psychological = res.data.average_score_psycho
          let social = res.data.average_score_social
          
          this.data.barChart = [biological.score, psychological.score, social.score]
          this.bColors = [biological.color, psychological.color, social.color]
          this.cColor = res.data.average_score.color;    

          if(res.data.allow_user_dashboard == false) {
            this.$refs['onboard-modal-3'].show();
          }

        }
      })
      .then((res) => {
        store.commit("formStoreModule/setShow", false);
      })
      .catch((error) => {
        console.log(error)

        store.commit("formStoreModule/setShow", false);

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
        return;
      });
    }
  },
  mounted() {
    let userData = store.state.app.userData;

    // Fill fullname for onboard-modal-1
    this.fullname = userData.name

    if(userData.email_verified_at == null) {
      this.handleUserNotVerified();
    } else {
      store.dispatch('app/showVerificationMessage', false)
      this.scrollToTop()
    }

    // Need verification toast event
    EventBus.$on('needVerificationToast', () => {
      this.handleUserNotVerified();
    });
    // Resend verification email event
    EventBus.$on('resendVerificationEmail', () => {
      this.resendVerificationEmail();
    });

    EventBus.$on('runTour', () => {
      this.$tours['myTour'].start()
    });

    store.commit("formStoreModule/setShow", true);

    // Return analytics data
    this.getAnalytics()

    EventBus.$on('getFreshAnalytics', (data) => {
      this.getAnalytics(data.from, data.to)
    })
  },
  watch: {
    $data: {
        handler: function(val, oldVal) {
          oldVal = val
        },
        deep: true,
        immediate: true
    }
},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.fix-height-for-cols {
  margin-bottom: 2rem!important;
  height: 100%!important;
}

.fix-height-for-cols .card {
  height: 100%;
}
</style>

<style>
.v-step__button {
  color: #6E6B7B!important;
}
</style>

<style lang="scss">
@import '../@core/scss/vue/libs/tour.scss';
</style>