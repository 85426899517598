<template>
    <div>
        <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
            <b-card>
                <b-row class="text-center">
                    <b-card-title class="mx-auto">
                        Overall feelings index
                    </b-card-title>
                </b-row>

                <b-row>
                    <b-card-sub-title class="mx-auto mb-2">
                        <what-does-this-mean type="emotional-score-explanation" />
                    </b-card-sub-title>
                </b-row>
                <div v-if="feelingsIndexData.length > 0">
                    <b-row class="no-gutters mb-2" v-for="(feelings, index) in feelingsIndexData" :key="index">
                        <div class="d-flex justify-content-between w-100">
                            <div>
                                {{ feelings.type }}
                            </div>
                            <div>
                                {{ feelings.average_score_for_feeling }}/10
                            </div>
                        </div>

                        <div class="w-100">
                            <b-progress
                                :value="feelings.average_score_for_feeling * 10"
                                max="100"
                                variant="mhc-dark"
                                height="3rem"
                                width="100%"
                                class="mt-05 mb-05 border-radius-0"
                            />

                            <div>
                                <span class="cursor-pointer mhc-link-blue-color font-weight-bold" @click="openDetailsModal(feelings)">
                                    View details
                                </span>
                            </div>
                        </div>

                    </b-row>
                </div>
                <div v-else>
                    <p class="mb-0 py-3 text-center">No mental health check results data was found for the selected period</p>
                </div>

                <feelings-index-details-modal />

                <b-row>
                    <b-button
                        @click="openForm"
                        variant="primary"
                        block
                        class="mt-3 mx-md-2 mx-0"
                        >Start another mental health check
                    </b-button>
                </b-row>

            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import EventBus from "@/main.js"
import { BRow, BCol, BOverlay, BButton, BAlert, BCard, BCardTitle, BCardSubTitle, BCardHeader, BCardBody, BProgress } from "bootstrap-vue";
import WhatDoesThisMean from "../../modals/WhatDoesThisMean.vue";
import FeelingsIndexDetailsModal from "../feelings-index/FeelingsIndexDetailsModal.vue";

export default {
    components: {
        BRow, BCol, BOverlay, BButton, BAlert, BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BProgress,
        WhatDoesThisMean, FeelingsIndexDetailsModal
    },
    props: {
        feelingsIndexData: {
            type: Array,
            default: []
        },
    },
    computed: {
        show() {
           return false;
        },
    },
    methods: {
        openForm() {
            this.$router.push({ name: 'form' });
        },
        openDetailsModal(feelings) {
            EventBus.$emit('openFeelingsIndexDetailsModal', feelings)
        }
    },
}
</script>

<style>
    .border-radius-0 {
        border-radius: 0;
    }

    .border-radius-0 .progress-bar {
        border-top-right-radius: 0rem!important;
        border-bottom-right-radius: 0rem!important;
    }

    .mhc-link-blue-color {
        color: #245bf0;
    }
</style>