<template>
  <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
    <b-card no-body id="nesto">
      <div class="w-100">
      <b-card-header>
        <!-- title, date and subtitle -->
        <div class="w-100">
          <b-card-title class="mb-1 w-100 line-chart-title-date d-flex justify-content-center">
            <div>
              Today's Daily Check-in: 
              <span v-if="hasCompletedDailyCheckIn" class="text-success">
                <u>COMPLETE</u>
              </span>
              <span v-else class="text-danger">
                <u>DUE NOW</u>
              </span>
            </div>
          </b-card-title>
          <!--
          <b-card-sub-title><what-does-this-mean type="home-circle-chart-bottom" /></b-card-sub-title>
          -->
        </div>
        <!--/ title and subtitle -->

      </b-card-header>
      
      <b-card-body v-if="hasCompletedDailyCheckIn">
        <p v-if="checkInData != null">
          How you’re doing today: {{ checkInData.user_mood.name }}<br>
          Strongest emotion today: {{ checkInData.user_sub_emotions.user_emotion.name }}<br>
          Specific {{ checkInData.user_sub_emotions.user_emotion.name }}  emotion: {{ checkInData.user_sub_emotions.name }}<br>
          Reason for feeling specific emotion: {{ checkInData.reason }}<br>
          More specific reason: {{ checkInData.more_specific_reason }}
        </p>

        <h4 class="mt-1">
          Your thoughts about today
        </h4>

        <p v-if="checkInData != null" v-html="checkInData.user_thoughts"></p>

      </b-card-body>
      <b-card-body v-else>
        <p class="text-center">
          Logging your mood and emotions everyday is an effective way to visualise how you’re doing while being able to spot patterns and make adjustments as early as possible.
        </p>
        <div class="d-flex justify-content-center">
          <b-button @click="openDailyCheckIn()" variant="danger" class="mt-2">
            Start Today’s Check-in
          </b-button>
        </div>
      </b-card-body>
    </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge, BFormSelect, BButton
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import WhatDoesThisMean from '../../modals/WhatDoesThisMean.vue'
import EventBus from '@/main';

export default {
  components: {
    VueApexCharts,
    flatPickr,
    WhatDoesThisMean,
    BOverlay,
    BCardHeader,
    BCard,
    BBadge,
    BFormSelect,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButton
  },
  props: ["lineChart"],
  data() {
    return {
      hasCompletedDailyCheckIn: null,
      show: false,
      checkInData: null
    }
  },
  methods: {
    openDailyCheckIn() {
      EventBus.$emit("myDailyCheckIn");
    }
  },
  mounted() {
    this.show = true;
    this.$http.get("/api/auth/has-completed-daily-check-in")
      .then((res) => {
        // user hasn't completed daily check in
        if(res.data == "") {
          this.hasCompletedDailyCheckIn = false;
        } else {
          this.hasCompletedDailyCheckIn = true;      
          this.checkInData = res.data
        }
        this.show = false;
      })
      .catch((error) => {
        console.log(error)
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again login",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#nesto {
  flex-direction: unset;
  display: flex;
  align-items: center;
}
</style>
