<template>
    <div>
        <b-row class="match-height">
            <b-col lg="4">
                <overall-wellbeing-index :wellbeingIndexData="wellbeingIndexData" />
            </b-col>
            
            <b-col lg="8">
                <wellbeing-index-line-chart :lineChartWellbeing="lineChart" />
            </b-col>
        </b-row>        

        <b-row class="match-height">
            <b-col lg="12">
                <recommended-toolkits v-if="wellbeingQuestionnaireId != null" :questionnaire="wellbeingQuestionnaireId" showButton />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BRow, BCol, BOverlay } from "bootstrap-vue";
import OverallWellbeingIndex from "../wellbeing-index/OverallWellbeingIndex.vue";
import WellbeingIndexLineChart from "../wellbeing-index/WellbeingIndexLineChart.vue";
import RecommendedToolkits from "../../toolkit/RecommendedToolkits.vue";

export default {
    components: {
        BRow, BCol, BOverlay,
        OverallWellbeingIndex,
        WellbeingIndexLineChart,
        RecommendedToolkits
    },
    computed: {

    },
    data() {
        return {
            wellbeingIndexData: [],
            wellbeingIndexLineChartData: [],
            wellbeingQuestionnaireId: null,
            lineChart: {
                series: [
                {
                    name: "Wellbeing index",
                    data: [],
                },
                ],
                chartOptions: {
                chart: {
                    zoom: {
                    enabled: false,
                    },
                    toolbar: {
                    show: false,
                    },
                },
                markers: {
                    strokeWidth: 7,
                    strokeOpacity: 1,
                    strokeColors: ["#59949c"],
                    colors: ["#59949c"],
                },
                colors: ["#59949c"],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },
                grid: {
                    xaxis: {
                    lines: {
                        show: true,
                    },
                    },
                },
                tooltip: {
                    custom(data) {
                    return `${'<div class="px-1 py-50"><span>'}${
                        data.series[data.seriesIndex][data.dataPointIndex]
                    }</span></div>`;
                    },
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    min: 0,
                    max: 10,
                    tickAmount: 10,
                    labels: {
                    formatter: (value) => { return parseInt(value) },
                    },
                    title: {
                        text: "Score out of 10"
                    }
                },
                },
            },
        }
    },
    methods: {
        getWellbeingIndex() {
            this.$http.get("/api/auth/wellbeing/analytics")
                .then((res) => {
                    if(res != undefined) {
                        this.wellbeingIndexData = res.data.wellbeing_index_data
                        this.wellbeingIndexLineChartData = res.data.wellbeing_index_line_chart_data
                    }
                })
                .then(() => {
                    // Fill LineChart values and options
                    let lineChartValues = [];
                    let lineChartOptions = [];

                    for(let y = 0; y < Object.keys(this.wellbeingIndexLineChartData).length; y++) {
                        let year = Object.keys(this.wellbeingIndexLineChartData)[y]

                        for(let m=0; m<Object.keys(this.wellbeingIndexLineChartData[year]).length; m++) {
                            let month = Object.keys(this.wellbeingIndexLineChartData[year])[m]

                            let axisName = year +'/'+ month

                            lineChartOptions.push(axisName)

                            lineChartValues.push(this.wellbeingIndexLineChartData[year][month].avg_score);
                        } 
                    }

                    this.lineChart.chartOptions.xaxis.categories = lineChartOptions;
                    this.lineChart.series[0].data = lineChartValues;
                })
                .catch((error) => {
                    console.log(error)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again login",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                });
        },
        getAllQuestionnaireCategories() {
            this.$http.get("/api/questionnaire-category")
                .then((res) => {                  
                    let wellbeingCategory = "";
                    res.data.forEach((element) => {
                        if(element.name == "My mental wellbeing") {
                            wellbeingCategory = element.id;                               
                        }
                    });                    
                    if(wellbeingCategory != "") {
                        this.getWellbeingQuestionnaire(wellbeingCategory)
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again login",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                });
        },
        getWellbeingQuestionnaire(wellbeingCategoryId) {
            this.$http.get("/api/questionnaire-subcategories/" + wellbeingCategoryId)
                .then((res) => {     
                    let wellbeingQuestionnaireId = "";
                    res.data.forEach((element) => {
                        element.questionnaires.forEach((questionnaire) => {
                            wellbeingQuestionnaireId = questionnaire.id;
                        })
                    });
                    this.wellbeingQuestionnaireId = wellbeingQuestionnaireId;
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again login",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                });
        }
    },
    mounted() {
        this.getWellbeingIndex()
        this.getAllQuestionnaireCategories()
    }
}
</script>

<style>
    .border-radius-0 {
        border-radius: 0;
    }

    .border-radius-0 .progress-bar {
        border-top-right-radius: 0rem!important;
        border-bottom-right-radius: 0rem!important;
    }
</style>